<template>
    <div style="background: var(--v-component-base) !important">
        <v-row justify="end" class="mb-2">
            <v-col cols="12" sm="12" md="8" lg="6">
                <el-input id="search-contract" suffix-icon="el-icon-search" v-model="search" clearable placeholder="Search">
                    <template slot="prepend">
                        <el-button id="add-contract-button"
                            @click="dialog = true, item = { modalType: 'Add', shippingContractTargets: [] }"><i
                                class="el-icon-plus"></i></el-button>
                    </template>
                </el-input>
            </v-col>
        </v-row>
        <v-data-table dense :items="contracts" :search="search" :loading="loading" :headers="headers">
            <template v-slot:[`item.action`]="{ item }">
                <v-btn id="edit-contract-button" small icon @click="editItem(item)">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn id="remove-contract-button" small icon @click="removeItem(item)">
                    <v-icon>delete</v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.regions`]="{ item }">
                <v-chip small outlined color="primary" v-for="target in item.shippingContractTargets" :key="target.id">
                    {{ target.region.name }}</v-chip>
            </template>
        </v-data-table>

        <!-- Add/Edit Role dialog -->
        <v-dialog v-model="dialog" persistent width="750px">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>{{ item.modalType }} Contract</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="item = {}, dialog = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" sm="12" md="6">
                            <v-select id="shipping-line" v-model="item.shippingLine" outlined dense label="Shipping Line"
                                :items="carriers" item-text="participant.name" item-value="participant.name"></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" id="shipping-contract">
                            <v-text-field label="Shipping Contract" dense outlined v-model="item.contractNumber"
                                clearable></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" id="end-date">
                            <v-menu ref="menu" v-model="datePicker" :close-on-content-click="false"
                                :return-value.sync="item.endDate" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field outlined dense v-model="item.endDate" label="End Date"
                                        prepend-inner-icon="event" readonly v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="item.endDate" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu = false">
                                        Cancel
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(item.endDate)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field id="target-number" label="Target number of containers" dense outlined
                                v-model="item.numberOfContainers" type="number" clearable></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="6"></v-col>
                    <v-col cols="12" sm="12" md="6"></v-col> -->
                        <v-col cols="12" sm="12" md="6" class="text-center" id="target-region" >
                            <v-select outlined dense v-model="selectedRegion" @change="addRegion()"
                                return-object label="Target Regions" :items="regions" item-text="name"
                                item-value="id"></v-select>

                            <v-chip class="mx-1 mb-1" v-for="(target, i) in item.shippingContractTargets" :key="i" close
                                @click:close="removeRegion(target)">{{ target.region.name }}</v-chip>
                        </v-col>

                        <v-col cols="12" class="text-center">
                            <el-button v-if="item.modalType == 'Add'" :loading="loading" style="min-width: 40%"
                                type="primary" :disabled="!item.contractNumber || !item.shippingLine || !item.endDate"
                                @click="saveContract" block> {{ !loading ? 'Submit' : '' }}</el-button>
                            <el-button v-else :loading="loading" style="min-width: 40%" type="primary"
                                :disabled="!item.contractNumber || !item.shippingLine || !item.endDate"
                                @click="saveContract" block> {{ !loading ? 'Save' : '' }}</el-button>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-dialog>

    </div>
</template>

<script>
export default {
    data: () => ({
        carriers: [],
        contracts: [],
        datePicker: false,
        dialog: false,
        headers: [{
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'action'
        },
        {
            text: 'Shipping Line',
            value: 'shippingLine',
            align: 'center',
        },
        {
            text: 'Contract Number',
            value: 'contractNumber',
            align: 'center',
        },
        {
            text: 'End Date',
            value: 'endDate',
            align: 'center'
        },
        {
            text: 'Regions',
            value: 'regions',
            align: 'center'
        }
        ],
        item: {},
        loading: false,
        regions: [],
        selectedRegion: null,
        search: '',
    }),
    created() {
        this.getCarrierList()
        this.getShippingContracts()
    },
    methods: {
        async addRegion() {
            if (this.item.id) {
                let result = await this.$API.createShippingContractTarget({ shippingContractId: this.item.id, regionId: this.selectedRegion.id })
                this.item.shippingContractTargets.push({ id: result.id, regionId: this.selectedRegion.id, region: this.selectedRegion })
            } else {
                this.item.shippingContractTargets.push({ regionId: this.selectedRegion.id, region: this.selectedRegion })
            }
            this.selectedRegion = null
        },
        editItem(item) {
            this.item = item
            this.item.modalType = 'Edit'
            this.dialog = true
        },
        async getCarrierList() {
            this.carriers = await this.$API.listCarriers()
            this.regions = await this.$API.getRegions()
        },
        async getShippingContracts() {
            this.loading = true
            this.contracts = await this.$API.getShippingContracts()
            this.loading = false
        },
        removeItem(item) {
            this.$confirm('Are you sure you want to delete this contract?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateShippingContract({
                    id: item.id,
                    isActive: false,
                    isDeleted: true
                })
                this.contracts.splice(this.contracts.indexOf(item), 1)
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        async removeRegion(item) {
            if (item.id) {
                await this.$API.updateShippingContractTarget({ id: item.id, isActive: false, isDeleted: true })
            }
            let index = this.item.shippingContractTargets.indexOf(item)
            this.item.shippingContractTargets.splice(index, 1)
        },
        async saveContract() {
            if (this.item.id) {
                await this.$API.updateShippingContract(this.item)
            } else {
                await this.$API.createShippingContract(this.item)
            }
            this.dialog = false
            this.item = {}
            this.getShippingContracts()
        },
    }
}
</script>
