<template>
    <div style="
      height: calc(92vh);
      width: 100%;
      background: var(--v-greyRaised-base) !important;">

        <v-card flat >
            <!-- <v-tabs height="30px" v-model="tab" class="tabby">
                <v-tab v-for="tab in tabs" 
                    :key="tab.value" 
                    :value="tab.value" 
                    :href="`#${tab.value}`"
                    style="font-size: 12px"
                    >
                    <v-icon small color="grey" class="mr-2">{{ tab.icon }}</v-icon>
                    <span>{{ tab.name }}</span>
                </v-tab>  
            </v-tabs>
            <div class="contents">
                <div class="mainContentWrap">
                    <div class="mainContent">
                        <v-tabs-items v-model="tab" style="height: inherit; width: 100%">
                            <v-tab-item style="height: inherit; width: 100%"  value="paymentTerms">
                                <v-card >
                            <v-subheader>
                                Payment Terms
                                <v-btn icon v-if="['@dotparse', '@loglive'].some(sub => $store.state.user.emailAddress.includes(sub))" @click="logItem(paymentTerms)">
                                        <v-icon>visibility</v-icon>
                                    </v-btn>
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-data-table disable-pagination hide-default-footer dense :items="paymentTerms" :headers="paymentTermHeaders">

                            </v-data-table>
                        </v-card>
                            </v-tab-item>
                            <v-tab-item style="height: inherit; width: 100%"  value="chargeItems"> -->
                                <ChargeItems style="height: inherit; width: 100%"/>
                            <!-- </v-tab-item>
                        </v-tabs-items>
                    </div>
                </div>
            </div> -->
        </v-card>

        
    </div>
</template>

<script>
    import ChargeItems from './ChargeItems.vue';
export default {
  components: {
      ChargeItems
  },
  data() {
    return {
       
        paymentTermHeaders: [
            {
                text: 'Action',
                value: 'action',
                sortable: false,
                align: 'center'
            },  
            {
                text: 'Term',
                value: 'sentence',
                sortable: false,
                align: 'center'
            },  
            {
                text: 'Inco Term',
                value: 'incoTerm',
                sortable: false,
                align: 'center'
            },  
            {
                text: 'Deal Type',
                value: 'dealType',
                sortable: false,
                align: 'center'
            },  
        ],
        chargeItems: [],
        
        paymentTerms: [],
        tabs: [
        // { name: "Payment Terms", value: "paymentTerms" }, //needs finalising
        { name: "Charge Items", value: "chargeItems" },
        ],
        tab: 0,
        };
    },
    async created() {
        // this.getOrgPaymentTerms()
        
    },
    methods: {
        
        logItem(item) {
            console.log(item)
        },
        async getOrgPaymentTerms() {
            this.paymentTerms = await this.$API.getOrgPaymentTerms();
        },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.innerContent {
  max-height: calc(100vh - 36px) !important;
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: scroll;
  background: var(--v-greyRaised-base) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: var(--v-greyRaised-lighten1) !important;
  border-radius: 0px;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(100vh - var(--toolBarHeight));
  background: var(--v-greyRaised-base) !important;
}
.mainContentWrap {
  background: var(--v-greyRaised-base) !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
.mainContentTabs {
  height: var(--tabHeight) !important;
}
.mainContent {
  max-height: calc(100vh - var(--toolBarHeight) - var(--tabHeight));
  height: 100%;
  width: 100%;
}

</style>